












































































































































































import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Bind, Debounce } from 'lodash-decorators'

// components
import OrderPaymentMethodModal from '@/components/modals/orders/OrderPaymentMethodModal.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UserCard from '@/components/cards/UserCard.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import MasterOrdersModule from '@/store/modules/master/orders'
import {
  AgreementsTypeEnum,
  CourseType,
  MasterOrderLargeResource,
  MasterOrdersOrderIdCodePostParams,
  OrderStatus,
} from '@/store/types'
import { OffersLink } from '@/store/types/docs'

type HrefSubject = {
  [key: number]: string,
}

export enum RemindIterationEnum {
  first = 'email-dobivka1',
  second = 'email-dobivka2',
  third = 'email-dobivka3',
}

@Component({
  components: {
    OrderPaymentMethodModal,
    Tag,
    TextInput,
    UserCard,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class OrderPaymentFormNew extends NotifyMixin {
  @Prop({ default: false })
  private isAuth!: boolean

  @Prop({ default: null })
  private remindIteration!: 'first' | 'second' | 'third'

  // Примененные скидки
  private get applyDiscounts() {
    return this.order.sales.filter(sale => sale.isApplied && !sale.isRequiredWhenBuy)
  }

  private get copyUrl() {
    return `${this.baseUrl}${this.$route.fullPath}`
  }

  private get docsUrl() {
    return `${this.baseUrl}/docs`
  }

  private get hrefOffer() {
    const href: HrefSubject = OffersLink
    if (this.order) {
      return this.order?.cashbox.value ? href[this.order.cashbox.value as number] : `${this.docsUrl}/offer`
    }
    return `${this.docsUrl}/offer`
  }

  private get order () {
    return MasterOrdersModule.order as MasterOrderLargeResource
  }

  private get courseID() {
    return this.order?.groups[0]?.course?.value + ''
  }

  private get isStatusWait () {
    return this.order && this.order.status.value === OrderStatus.WAIT
  }

  private get self () {
    return AuthModule.self && AuthModule.self.id === this.order.user.id
  }

  private get isPaid () {
    return this.order.status.value === OrderStatus.PAID
  }

  private get isWait() {
    return this.order.status.value === OrderStatus.WAIT
  }

  // Сумма всех скидок заказа, включая промокод
  private get discountPrice() {
    return this.order.packageOfferPrice + (this.order.promoCode?.realDiscount ?? 0) + this.order.sales.reduce((acc, cur) => acc += cur.realDiscount, 0)
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  // Сумма заказа без учета скидок, промокода и пакетного предложения
  private get orderOriginalPriceSum() {
    if (!this.order.groups.length)
      return 0
    if (this.order.groups[0].course.type === CourseType.SPECIAL) {
      return this.order.sales.length ? this.order.originalPrice : this.order.groups[0].fakePrice ?? 0
    }
    let sum = 0
    this.order.groups.map(group => group.packages.forEach(pack => sum += pack.originalPrice))
    return sum
  }

  private get isSinglePackage() {
    return this.order.groups.length === 1 && this.order.groups[0].packages.length === 1
  }

  private baseUrl = process.env.VUE_APP_BACKEND_ENDPOINT
  private email = ''
  private isRecurrentActivated = false
  private special = CourseType.SPECIAL
  private showPromocodeForm = false
  private showMore = 1

  private showOrderPaymentMethodModal = false

  private form: MasterOrdersOrderIdCodePostParams = {
    code: '',
  }

  @Debounce(500)
  @Bind
  private handleAddPromocode() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          MasterOrdersModule.addPromocodeToOrder({
            orderID: this.order.id,
            params: this.form,
          })
            .then((response: MasterOrderLargeResource) => {
              this.$emit('updateOrder', response)
              this.showPromocodeForm = false
              this.notifySuccess('Промокод применен')
            })
            .catch(this.notifyError)
        }
      })
  }

  @Debounce(500)
  @Bind
  private handleDeletePromocode() {
    MasterOrdersModule.deletePromocodeFromOrder(this.order.id)
      .then((response: MasterOrderLargeResource) => {
        this.$emit('updateOrder', response)
        this.notifySuccess('Промокод удален')
      })
      .catch(this.notifyError)
  }

  private handlePay() {
    const form: any = this.$refs.emailForm

    if (form) {
      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            if (this.order.totalPrice) {
              this.showOrderPaymentMethodModal = true
            } else {
              this.handleSubmit('default')
            }
          } else {
            if (!this.isAuth) {
              this.notifyError('Проверьте введённые данные')
            }
          }
        })
    } else {
      if (this.order.totalPrice) {
        this.showOrderPaymentMethodModal = true
      } else {
        this.handleSubmit('default')
      }
    }
  }

  @Debounce(500)
  @Bind
  private handleSubmit(method: 'default' | 'sbp') {
    // Нужно, чтобы отобразить рекомендованные курсы на странице успешной оплаты
    localStorage.setItem('lastOrderCourseID', this.courseID)

    if (this.order.groups[0]?.course.value === 346) {
      try {
        this.$metrika.reachGoal('pay')
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
    }
    if (this.remindIteration) {
      try {
        this.$metrika.reachGoal(RemindIterationEnum[this.remindIteration])
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
    }
    if (this.isAuth) {
      AuthModule.agreement({
        agreements: this.order.sales.length || this.order.promoCode ? [AgreementsTypeEnum.OFFER_AGREEMENT, AgreementsTypeEnum.SALE_PROMOTION_AGREEMENT] : [AgreementsTypeEnum.OFFER_AGREEMENT],
      })
        .finally(() => {
          window.location.replace(`${process.env.VUE_APP_BACKEND_API_ENDPOINT}/payment/orders/${this.order.uuid}/pay${this.isRecurrentActivated ? `?is_recurrent=true&payment_system=${method}` : `?payment_system=${method}`}`)
        })
    } else {
      window.location.replace(`${process.env.VUE_APP_BACKEND_API_ENDPOINT}/payment/orders/${this.order.uuid}/pay?email=${this.email}${this.isRecurrentActivated ? '&is_recurrent=true' : ''}&payment_system=${method}`)
    }
  }
}
